<template>
  <div v-if="selected_entity2" id="domain-entity-requirements">
    <div id="domain-entity-requirements-header">
      <div class="d-inline-flex w-100 justify-content-between">
        <h3 id="domain-entity-requirements-selected-title" class="ml-1 text-primary">
          {{ selected_entity2.context.details.name }}
        </h3>

        <p>
          <span
            v-for="(data, index) in entityType"
            id="domain-entity-requirements-selected-entity-type"
            :key="index"
            class="mr-2"
          >
            <feather-icon
              v-b-tooltip.hover.above="`Type: ${data}`"
              :icon="entityLabel(data)"
              class="mr-50"
            />
            <span class="text-muted font-weight-bolder">{{ data }}</span>
          </span>
        </p>
      </div>

      <div class="d-inline-flex w-100 justify-content-around">
        <h5>Lineage:</h5>
        <div class="ml-1">
          <div v-if="selected_entity2.context.parent_rel">
            Is a
            <strong>
              {{
                selected_entity2.context.parent_rel.rel_type === "PART_OF"
                  ? "part"
                  : "type"
              }}
              of:
            </strong>
            <strong>
              <router-link
                :to="{
                  name: 'domain_ontology',
                  query: { focus: selected_entity2.context.parent_rel.parent },
                }"
              >
                {{ selected_entity2.context.parent_rel.parent_name }}
                <i class="fas fa-external-link-alt" />
              </router-link>
            </strong>
          </div>
          <div v-if="selected_entity2.context.parent_rel && findChildren().length > 0">
            Is the <strong>aggregate parent</strong> of:
            <ul>
              <li v-for="child in findChildren()" :key="child.id">
                <strong>
                  <router-link
                    :to="{
                      name: 'domain_ontology',
                      query: {
                        focus: child.id,
                      },
                    }"
                  >
                    {{ child.name }}
                    <i class="fas fa-external-link-alt" />
                  </router-link>
                </strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <hr class="mt-3">
    <vue-perfect-scrollbar
      :settings="{ maxScrollbarLength: 60, wheelPropagation: false}"
      class="ps-customizer-area scroll-area"
    >
      <div style="height: 60rem">
        <!-- Quality Attribute Requirements -->
        <generic-accordion-list-group
          title-prefix="quality attribute"
          title="requirements"
          :item-count="selected_entity2.constraints.length"
        >
          <template v-slot:titleButton>
            <b-button
              v-b-tooltip.hover.top="'Create and link a Quality Attribute Requirement / Constraint'"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              class="btn-icon"
              variant="flat-success"
              @click="addConstraint"
            >
              <feather-icon
                icon="PlusIcon"
              />
            </b-button>
          </template>
          <template v-slot:content>
            <b-list-group>
              <b-list-group-item
                v-for="item in selected_entity2.constraints"
                :key="item.id"
                class="d-flex justify-content-between"
              >
                <div>
                    <span class="text-primary">{{ item.name }}</span>
                    <span class="position-absolute position-right-0 mr-1">{{ item.classification }}</span>
                    <div class="text-muted">{{ item.gist }}</div>
                    <div v-if="item.scale && item.scale !== ''">
                      <b>Scale: </b>{{ item.scale }}
                    </div>
                    <div v-if="item.must && item.must !== ''"><b>Must (Essential): </b>{{ item.must }}</div>
                    <div v-if="item.plan && item.plan !== ''"><b>Plan  (Very Important): </b>{{ item.plan }}</div>
                    <div v-if="item.stretch && item.stretch !== ''"><b>Stretch (Important): </b>{{ item.stretch }}</div>
                    <div v-if="item.wish && item.wish !== ''"><b>Wish (Desirable): </b>{{ item.wish }}</div>
                    <div v-if="item.qualifier && item.qualifier !== ''"><b>Qualification: </b>{{ item.qualifier }}</div>
                  </div>
                    <b-button-group size="sm" class="position-relative mt-3" style="height: 2rem">
                      <b-button
                        title="Generate Requirements from Quality Attribute Constraint"
                        variant="flat-success"
                        class="p-50"
                        @click="genConstraintReqs(item)"
                      >
                        <feather-icon icon="LoaderIcon" />
                      </b-button>
                      <b-button
                        title="Edit Quality Attribute Constraint"
                        variant="flat-primary"
                        class="p-50"
                        @click="editConstraint(item)"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>
                      <b-button
                        title="Delete Quality Attribute Constraint"
                        variant="flat-danger"
                        class="p-50"
                        @click="deleteConstraint(item)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </b-button-group>
              </b-list-group-item>
            </b-list-group>
          </template>
        </generic-accordion-list-group>
        <!-- Compatibility Requirements-->
        <generic-accordion-list-group
          title-prefix="requirements of"
          title="compliance"
          :item-count="selected_entity2.compliance.length"
        >
          <template v-slot:titleButton>
            <b-button
              v-b-tooltip.hover.top="'Create and link a Compliance Requirement'"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              class="btn-icon"
              variant="flat-success"
              @click="$bvModal.show('add-compliance-modal')"
            >
              <feather-icon
                icon="PlusIcon"
              />
            </b-button>
          </template>
          <template v-slot:content>
            <b-list-group>
              <b-list-group-item
                v-for="item in selected_entity2.compliance"
                :key="item.id"
                class="d-inline-flex w-100 justify-content-between"
              >
                <b-row>
                  <b-col>
                    <div v-if="item.reference">
                      Comply with
                      <strong>
                        <router-link
                          :to="{
                            name: 'domain_ontology',
                            query: { focus: item.reference.id },
                          }"
                        >
                          {{ item.reference.qualified_name ? item.reference.qualified_name : item.reference.name }}
                          <i class="fas fa-external-link-alt" />
                        </router-link>
                      </strong>
                    </div>
                    <span
                      v-else
                      class="font-small-2 text-danger font-weight-bold"
                      title="This Compliance Requirement has no component reference link in the database."
                    >
                      [ REFERENCE MISSING ]
                    </span>
                  </b-col>
                  <b-col cols="2">
                    <b-button-group size="sm">
                      <b-button
                        title="Generate Textual Requirement"
                        variant="flat-success"
                        class="p-50"
                        @click="genComplianceReqs(item)"
                      >
                        <feather-icon icon="LoaderIcon" />
                      </b-button>
                      <b-button
                        title="Edit Compliance Requirement"
                        variant="flat-primary"
                        class="p-50"
                        @click="editCompliance(item)"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>
                      <b-button
                        title="Delete Compliance Requirement"
                        variant="flat-danger"
                        class="p-50"
                        @click="deleteCompliance(item)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </b-button-group>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </template>
        </generic-accordion-list-group>
        <!-- Function Performance Requirements-->
        <generic-accordion-list-group
          title-prefix="requirements of function"
          title="performance"
          :item-count="selected_entity2.performance.performers.length + selected_entity2.performance.functions.length"
        >
          <template v-slot:content>
            <b-list-group>
              <b-list-group-item
                v-for="perf in selected_entity2.performance.performers"
                :key="perf.id"
                class="d-inline-flex w-100 justify-content-between"
              >
                <b-row>
                  <b-col>
                    <strong>
                      <router-link
                        :to="{
                          name: 'domain_ontology',
                          query: { focus: perf.id },
                        }"
                      >
                        {{ perf.qualified_name ? perf.qualified_name : perf.name }}
                        <i class="fas fa-external-link-alt" />
                      </router-link>
                    </strong>
                    able to
                    <strong>
                      <router-link
                        :to="{
                          name: 'domain_ontology',
                          query: { focus: selected_entity2.context.details.id },
                        }"
                      >
                        {{ selected_entity2.context.details.qualified_name ? selected_entity2.context.details.qualified_name : selected_entity2.context.details.name }}
                        <i class="fas fa-external-link-alt" />
                      </router-link>
                    </strong>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
            <b-list-group>
              <b-list-group-item
                v-for="fn in selected_entity2.performance.functions"
                :key="fn.id"
                class="d-inline-flex w-100 justify-content-between"
              >
                <b-row>
                  <b-col>
                    <strong>
                      <router-link
                        :to="{
                          name: 'domain_ontology',
                          query: { focus: selected_entity2.context.details.id },
                        }"
                      >
                        {{ selected_entity2.context.details.qualified_name ? selected_entity2.context.details.qualified_name : selected_entity2.context.details.name }}
                        <i class="fas fa-external-link-alt" />
                      </router-link>
                    </strong>
                    able to
                    <strong>
                      <router-link
                        :to="{
                          name: 'domain_ontology',
                          query: { focus: fn.id },
                        }"
                      >
                        {{ fn.qualified_name ? fn.qualified_name : fn.name }}
                        <i class="fas fa-external-link-alt" />
                      </router-link>
                    </strong>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </template>
        </generic-accordion-list-group>
        <!-- Function Enablement Requirements-->
        <generic-accordion-list-group
          title-prefix="requirements of function"
          title="enablement"
          :item-count="selected_entity2.enablement.performers.length + selected_entity2.enablement.functions.length"
        >
          <template v-slot:content>
            <b-list-group>
              <b-list-group-item
                v-for="perf in selected_entity2.enablement.performers"
                :key="perf.id"
                class="d-inline-flex w-100 justify-content-between"
              >
                <b-row>
                  <b-col>
                    <strong>
                      <router-link
                        :to="{
                          name: 'domain_ontology',
                          query: { focus: perf.id },
                        }"
                      >
                        {{ perf.qualified_name ? perf.qualified_name : perf.name }}
                        <i class="fas fa-external-link-alt" />
                      </router-link>
                    </strong>
                    enables
                    <strong>
                      <router-link
                        :to="{
                          name: 'domain_ontology',
                          query: { focus: selected_entity2.context.details.id },
                        }"
                      >
                        {{ selected_entity2.context.details.qualified_name ? selected_entity2.context.details.qualified_name : selected_entity2.context.details.name }}
                        <i class="fas fa-external-link-alt" />
                      </router-link>
                    </strong>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
            <b-list-group>
              <b-list-group-item
                v-for="fn in selected_entity2.enablement.functions"
                :key="fn.id"
                class="d-inline-flex w-100 justify-content-between"
              >
                <b-row>
                  <b-col>
                    <strong>
                      <router-link
                        :to="{
                          name: 'domain_ontology',
                          query: { focus: selected_entity2.context.details.id },
                        }"
                      >
                        {{ selected_entity2.context.details.qualified_name ? selected_entity2.context.details.qualified_name : selected_entity2.context.details.name }}
                        <i class="fas fa-external-link-alt" />
                      </router-link>
                    </strong>
                    enables
                    <strong>
                      <router-link
                        :to="{
                          name: 'domain_ontology',
                          query: { focus: fn.id },
                        }"
                      >
                        {{ fn.qualified_name ? fn.qualified_name : fn.name }}
                        <i class="fas fa-external-link-alt" />
                      </router-link>
                    </strong>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </template>
        </generic-accordion-list-group>
        <!-- Spec Requirements -->
        <generic-accordion-list-group
          title-prefix="associated"
          title="requirements(specified)"
          :item-count="findRequirements().length"
        >
          <template v-slot:titleButton>
            <b-button
              v-b-tooltip.hover.top="'Associate Requirement'"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              class="btn-icon"
              variant="flat-success"
              @click="$bvModal.show('associator-generic-requirements')"
            >
              <feather-icon
                icon="LinkIcon"
              />
            </b-button>
          </template>
          <template v-slot:content>
            <list-group-requirement
              label="Requirements"
              :entity-array="findRequirements()"
              modal="associator-generic-requirements"
              hide-header
            />
          </template>
        </generic-accordion-list-group>
      </div>
    </vue-perfect-scrollbar>

    <!-- Modals -->
    <div>
      <AddConstraint />
      <EditConstraint />
      <DeleteConstraint />
      <GenerateRequirementsConstraint />
      <AddCompliance />
      <EditCompliance />
      <DeleteCompliance />
      <GenerateRequirementsCompliance />
      <associator-generic
        name="Requirements"
        :associated-items="selectedReqs.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.display_id,
            },
            text: `${item.display_id}. ${item.object_text.replace(/<\/?[^>]+(>|$)/g, '')}`,
          }
        })"
        }
        :all-items="allReqs.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.display_id,
            },
            text: `${item.display_id}. ${item.text}`,
          }
        })"
        @associated="associateRequirementComponent"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import ListGroupRequirement from '@/components/Forms/ListGroups/ListGroupRequirement.vue'
import coreService from '@/libs/api-services/core-service'
import DeleteConstraint from '@/components/Domain/Modals/Properties/ConstraintDelete.vue'
import EditConstraint from '@/components/Domain/Modals/Properties/ConstraintEdit.vue'
import AddConstraint from '@/components/Domain/Modals/Properties/ConstraintAdd.vue'
import GenerateRequirementsConstraint from '@/components/Domain/Modals/GenerateRequirementsConstraint.vue'
import { v4 as uuidv4 } from 'uuid'
import AddCompliance from '@/components/Domain/Modals/ComplianceAdd.vue'
import EditCompliance from '@/components/Domain/Modals/ComplianceEdit.vue'
import DeleteCompliance from '@/components/Domain/Modals/ComplianceDelete.vue'
import GenerateRequirementsCompliance from '@/components/Domain/Modals/GenerateRequirementsCompliance.vue'
import GenericAccordionListGroup from '@/components/Generic/GenericAccordionListGroup.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import AssociatorGeneric from '@/components/Generic/Associators/AssociatorGeneric.vue'

export default {
  components: {
    AssociatorGeneric,
    GenericAccordionListGroup,
    ListGroupRequirement,
    GenerateRequirementsConstraint,
    AddConstraint,
    EditConstraint,
    DeleteConstraint,
    AddCompliance,
    EditCompliance,
    DeleteCompliance,
    GenerateRequirementsCompliance,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      allModels: [],
      selectedModel: null,
      id: '',
      name: '',
      acronym: '',
      abstract: false,
      multiplicity: '',
      stereotype_selected: [],
      description: '',
      validity: 'Valid',
      context_data: { attributes: {} },
      allReqs: [],
      selectedReqs: [],
      selected_enablers: [],
      c_fields: [
        {
          key: 'name',
          label: 'Name / Tag',
          sortable: true,
        },
        {
          key: 'gist',
          label: 'Desc.',
        },
        {
          key: 'requirement',
          label: 'Requirement',
        },
        {
          key: 'actions',
          label: '',
          thStyle: { width: '10%' },
        },
      ],
      c_fields2: [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
          thStyle: { width: '10%' },
        },
      ],
      constraints: [],
      quill_editor_options: {
        theme: 'snow',
        modules: {
          toolbar: '#toolbar',
        },
      },
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
    entityType() {
      return this.selected_entity2.context.labels ? this.selected_entity2.context.labels.filter(e => e !== 'Component') : []
    },
    ...mapState({
      selectedModelComputed: state => state.model,
    }),
  },
  watch: {
    selected_entity2(newVal) {
      this.selectedReqs = this.findRequirements()
      this.fillFields(newVal)
    },
  },
  mounted() {
    this.$store.dispatch('domainModel/getComponents')
    this.$store.dispatch('releases/getReleases')
    this.$store.dispatch('interfaces/fetchInterfaces')
    this.fillFields(this.selected_entity2)
    this.populate()
  },
  methods: {
    populate() {
      const modelId = this.$store.state.model.id
      const selectedId = this.selected_entity2.context.details.id
      this.$http.get('/api/v2/requirements/get_requirements_simple', { params: { model: modelId } })
        .then(({ data }) => {
          this.allReqs = data
        })
      this.selectedReqs = this.findRequirements()
    },
    fillFields(n) {
      const con = n.context
      this.name = con.details.name
      this.acronym = con.details.acronym
      this.description = con.details.description
      this.stereotype_selected = con.labels
      this.abstract = con.details.abstract === true
      this.multiplicity = con.details.multiplicity
      this.validity = con.details.validity
      this.id = con.details.id
      this.selected_enablers = con.relations.edges.filter(e => e.name === 'Enabled by')
        .map(x => ({ id: x.target, name: x.target_str, justification: x.properties.justification }))
    },
    entityLabel(data) {
      // Depending on the label of the Entity, return a different icon
      switch (data) {
      case 'Resource':
        return 'BookOpenIcon'
      case 'Function':
        return 'ZapIcon'
      case 'Performer':
        return 'UserIcon'
      case 'Standard':
        return 'FileTextIcon'
      case 'Reference':
        return 'EyeIcon'
      case 'Agreement':
        return 'PenToolIcon'
      case 'Capability':
        return 'CheckSquareIcon'
      case 'Activity':
        return 'ActivityIcon'
      case 'Service':
        return 'ToolIcon'
      case 'System':
        return 'ServerIcon'
      case 'Objective':
        return 'TargetIcon'
      default:
        return ''
      }
    },
    async fetchComponents(modelId, subtype) {
      await this.$store.dispatch('domainModel/getComponentsForModel', { modelId, subtype: '' })
      if (modelId && this.$store.state.model.id !== modelId) {
        // now returns all components, not just the subtype
        return this.$store.state.domainModel[modelId].components
      }
      return this.$store.state.domainModel.components
    },
    async fetchSharedModels() {
      return [this.$store.state.model, ...await coreService.modelApi.getLinkedModels(this.$store.state.model.id)]
    },
    async linkComponents(payload, allocationType) {
      this.fields[`selected${allocationType}`] = payload.map(x => ({
        id: x.id,
        name: x.name,
        justification: x.justification,
      }))
      this.allocateFn(allocationType)
    },
    // Find related components that are children and not the parent
    findChildren() {
      const rels = this.selected_entity2.context.relationships
      const parent = this.selected_entity2.context.parent_rel

      const output = []

      // Sort through all rels, find related components
      for (let i = 0; i < rels.length; i++) {
        if (rels[i].rel_type === 'TYPE_OF' || rels[i].rel_type === 'PART_OF') {
          if (rels[i].target_props.id !== parent.parent) {
            // Find related comps that aren't parents
            output.push({
              name: rels[i].target_props.qualified_name,
              id: rels[i].target_props.id,
              rel_type: rels[i].rel_type,
            })
          }
        }
      }

      return output
    },
    // Find all relations that are requirements
    findRequirements() {
      const rels = this.selected_entity2.context.relationships

      const output = []

      // Sort through all rels, find all labeled "Issue"
      for (let i = 0; i < rels.length; i++) {
        if (rels[i].labels.includes('Requirement')) {
          output.push(rels[i].target_props)
        }
      }
      return output
    },
    addConstraint() {
      this.$store.dispatch('domainModel/selectConstraint', null)
        .then(() => {
          const selectedId = this.selected_entity2.context.details.id
          const payload = {
            model: this.$store.state.model.id,
            name: 'New Constraint',
            id: uuidv4(),
            requirements: [],
          }
          this.$http.post(`/api/v2/domain_model/add_constraint/${selectedId}`, payload).then(async result => {
            this.loading_status = false
            this.$bvModal.hide('add-constraint-modal')
            const pl = { ...result.data, ...payload, ...{ name: '' } }
            await this.$store.dispatch('domainModel/selectConstraint', pl)
            this.$bvModal.show('edit-constraint-modal')
          })
        })
    },
    editConstraint(val) {
      this.$store.dispatch('domainModel/selectConstraint', val)
        .then(() => {
          console.log('Properties::editConstraint: ', val)
          this.$bvModal.show('edit-constraint-modal')
        })
    },
    deleteConstraint(val) {
      this.$store.dispatch('domainModel/selectConstraint', val)
        .then(() => {
          this.$bvModal.show('delete-constraint-modal')
        })
    },
    genConstraintReqs(val) {
      console.debug('Generate requirements from constraint: ', val)
      this.$store.dispatch('domainModel/selectConstraint', val)
        .then(() => {
          this.$bvModal.show('generate-constraint-requirements-modal')
        })
    },
    addCompliance() {
      this.$store.dispatch('domainModel/selectCompliance', null)
        .then(() => {
          console.log('Properties::addCompliance: ')
          const selectedId = this.selected_entity2.context.details.id
          const payload = {
            model: this.$store.state.model.id,
            name: 'New Constraint',
            id: uuidv4(),
            requirements: [],
          }
          this.$http.post(`/api/v2/domain_model/add_compliance/${selectedId}`, payload).then(async result => {
            this.loading_status = false
            this.$bvModal.hide('add-constraint-modal')
            const pl = { ...result.data, ...payload, ...{ name: '' } }
            await this.$store.dispatch('domainModel/selectConstraint', pl)
            this.$bvModal.show('edit-constraint-modal')
          })
        })
    },
    associateRequirementComponent(reqs) {
      const payload = {
        model: this.$store.state.model.id,
        requirements: reqs,
        overwrite: true,
      }
      this.$http
        .post(`/api/v2/domain_model/components/${this.selected_entity2.context.details.id}/requirements`, payload)
        .then(() => {
          this.$store.dispatch('domainModel/selectEntity2', this.selected_entity2.context.details.id)
            .then(() => {
              this.syncAllocations()
            })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Requirements associated',
              text: 'Successfully associated Requirements with the Component',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(r => {
          console.error(`[AssociateRequirementsModal] submit failed - ${r}`)
          console.error(r)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to associate Requirements',
              text: `An error occurred when attempting to associate Requirements with the Component.
              Server returned Status ${r}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    editCompliance(val) {
      this.$store.dispatch('domainModel/selectCompliance', val)
        .then(() => {
          this.$bvModal.show('edit-compliance-modal')
        })
    },
    deleteCompliance(val) {
      console.log('Properties::deleteCompliance: ', val)
      this.$store.dispatch('domainModel/selectCompliance', val)
        .then(() => {
          this.$bvModal.show('delete-compliance-modal')
        })
    },
    genComplianceReqs(val) {
      console.debug('Generate requirements from compliance: ', val)
      this.$store.dispatch('domainModel/selectCompliance', val)
        .then(() => {
          this.$bvModal.show('generate-compliance-requirements-modal')
        })
    },
  },
}
</script>
<style lang="css">
#domain-entity-requirements .list-group {
  max-height: 20vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.disabled-item:active {
  background-color: inherit !important;
  color: inherit !important;
}
.disabled-item:hover {
  background-color: inherit !important;
}
</style>
