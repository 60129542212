<template>
  <b-list-group-item
    v-b-tooltip.hover.top="`Click to view issue`"
    button
    @click="issueModalView(issue.id, null)"
  >
    <div v-if="issue.display_id">
      <div class="w-100 d-inline-flex justify-content-between">
        <h6 class="text-primary font-weight-bolder">
          {{ issue.display_id }}
        </h6>
        <div>
          <b-badge v-if="issue.status === 'Closed' || issue.status === 'Withdrawn'" class="mr-50" variant="secondary">
            {{ issue.status }}
          </b-badge>
          <b-badge v-else-if="issue.status === 'Deferred'" class="mr-50" variant="warning">
            {{ issue.status }}
          </b-badge>
          <b-badge v-else class="mr-50" variant="success">
            {{ issue.status }}
          </b-badge>

          <b-badge class="mr-50">
            Severity: {{ issue.severity }}
          </b-badge>
          <b-badge>
            Classification: {{ issue.classification }}
          </b-badge>
        </div>
      </div>
      <div>
        <small
          v-if="issue.updated"
          v-b-tooltip.hover.top="`Updated by ${getUserUsername(issue.updated_by)} at ${issue.updated}  -  Created by ${getUserUsername(issue.created_by)} at ${issue.created} `"
        >
          Updated {{ issue.updated | diffForHumans }}
        </small>
        <small v-else v-b-tooltip.hover.top="`Created by ${getUserUsername(issue.created_by)} at ${issue.created}`">
          Created {{ issue.created | diffForHumans }}
        </small>
      </div>

      <hr class="w-75 mb-25">

      <div class="mt-0 font-weight-bolder" v-sanitized-html="issue.name" />
      <div class="pl-50" v-sanitized-html="issue.description" />
    </div>
    <div v-else>
      {{ issue.id }}
    </div>
  </b-list-group-item>
</template>

<script>
export default {
  name: 'ListGroupItemIssue',
  props: {
    issue: {
      type: Object,
      required: true,
    },
  },
}
</script>
