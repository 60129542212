<template>
  <b-card :id="`accordion-menu-${title}`" no-body class="mb-50 border">
    <b-card-header header-tag="header" class="p-0" role="tab">
      <b-button-group class="w-100">
        <!-- Optional: Add/Link button -->
        <slot name="titleButton" />

        <span class="text-primary position-absolute w-100 text-center mt-75 pointer-events-none">
          {{ titlePrefix | capitalizeEachWord | addTrailingSpace }}{{ title | capitalizeEachWord }}
        </span>
        <!-- Header and open/close button -->
        <b-button
          v-b-toggle="'accordion-menu-' + title"
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          class="btn-icon"
          variant="flat-primary"
          block
          @click="onTitleClick"
        >
          <b-badge
            class="float-right"
            :variant="itemCount > 0 ? 'info' : 'muted'"
          >
            {{ itemCount ? itemCount : '-' }}
          </b-badge>
        </b-button>
      </b-button-group>
    </b-card-header>

    <b-collapse
      :id="'accordion-menu-' + title"
      :accordion="'selected-context' + title"
      role="tabpanel"
    >
      <!-- Optional: Add/Show buttons -->
      <b-card-text>
        <slot name="addShowButton" />
      </b-card-text>
      <!-- Main content -->
      <b-card-text v-if="itemCount > 0">
        <slot name="content" />
      </b-card-text>

      <!-- Empty list -->
      <b-card-text v-else>
        <b-list-group>
          <b-list-group-item class="d-inline-flex w-100 justify-content-between">
            <span class="font-weight-bold">
              No associated <span class="font-weight-bolder">{{ title }}</span>.
            </span>
          </b-list-group-item>
        </b-list-group>
      </b-card-text>
    </b-collapse>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BCard,
  BListGroup,
  VBTooltip,
} from 'bootstrap-vue'

export default {
  name: 'GenericAccordionListGroup',
  components: {
    BCard,
    BButton,
    BListGroup,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    titlePrefix: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    onTitleClick: {
      type: Function,
      required: false,
      default: () => {},
    },
    itemCount: {
      type: Number,
      required: false,
      default: () => 0,
    },
  },
}
</script>

<style scoped lang="scss">

</style>
