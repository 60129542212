<template>
  <b-form v-if="selected_entity2" @submit="submitFunction">
    <h6 class="text-primary">
      {{ selected_entity2.context.details.name }}
      <feather-icon
        v-b-tooltip.hover.above="`Edit Image`"
        icon="ImageIcon"
        class="d-inline-block position-absolute position-right-0 mr-1"
        style="cursor: pointer;"
        @click="openImageModal"
      />
    </h6>
    <span
      v-for="(data, index) in entityType"
      :key="index"
      class="mb-25 mr-50"
    >
      <feather-icon
        v-b-tooltip.hover.above="`Type: ${data}`"
        :icon="entityLabel(data)"
        class="font-small-2 mr-50"
      />
      <span class="font-small-2 text-muted font-weight-bolder">{{ data }}</span>
    </span>
    <!-- Lineage -->
    <h6>
      Lineage
    </h6>
    <b-row>
      <b-col
        v-if="selected_entity2.context.parent_rel"
        cols="9"
      >
        Is a
        <strong>
          {{
            selected_entity2.context.parent_rel.rel_type === "PART_OF"
              ? "part"
              : "type"
          }}
          of:
        </strong>
        <strong>
          <router-link
            :to="{
              name: 'domain_ontology',
              query: { focus: selected_entity2.context.parent_rel.parent },
            }"
          >
            {{ selected_entity2.context.parent_rel.parent_name }}
            <i class="fas fa-external-link-alt" />
          </router-link>
        </strong>
      </b-col>
      <b-col
        v-if="selected_entity2.context.parent_rel"
        cols="3"
      >
        <b-button
          v-if="selected_entity2.context.parent_rel.rel_type === 'PART_OF'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="float-left btn-sm"
          @click="changeRel"
        >
          Change to subtype
        </b-button>
        <b-button
          v-else
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="float-left btn-sm"
          @click="changeRel"
        >
          Change to part
        </b-button>
      </b-col>
      <b-col v-if="findChildren().length > 0" cols="12">
        Is the parent of:
        <ul style="max-height: 20rem" class="overflow-y-scroll">
          <li v-for="child in findChildren()" :key="child.id">
            <strong>
              <router-link
                :to="{
                  name: 'domain_ontology',
                  query: {
                    focus: child.id,
                  },
                }"
              >
                {{ child.name }}
                <i class="fas fa-external-link-alt" />
              </router-link>
            </strong>
          </li>
        </ul>
      </b-col>
    </b-row>
    <!-- ./Lineage -->
    <hr class="mb-2">
    <b-row>
      <b-col cols="8">
        <label for="entityInput1">Entity Name</label>
        <b-form-input
          id="entityInput1"
          v-model="name"
          size="sm"
          required
        />
      </b-col>

      <b-col>
        <label for="entityInput2">Acronym</label>
        <b-form-input id="entityInput2" v-model="acronym" size="sm" />
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col>
        <label for="entityRich1">Description</label>
        <div v-show="!showDescriptionEditor" @click="editDescription">
          <p v-sanitized-html="description"></p>
          <span v-if="!description" class="text-muted">Click to add description</span>
        </div>
        <tip-tap-editor
          v-show="showDescriptionEditor"
          id="entityRich1"
          v-model="description"
          min-height="8"
          max-height="8"
          :allow-image-upload="false"
        />
      </b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col>
        <label for="entitySelect1">Stereotype</label>
        <b-form-select
          id="entitySelect1"
          v-model="stereotype_selected"
          :options="stereotypes"
          multiple
          :select-size="5"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <label for="entityInput3">Multiplicity</label>
        <b-form-input
          id="entityInput3"
          v-model="multiplicity"
          size="sm"
        />

        <b-form-checkbox
          v-model="abstract"
          style="margin-right:60px"
          class="mt-2"
        >
          Abstract
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <label for="entitySelect2">Classification</label>
        <b-form-select
          id="entitySelect2"
          v-model="classification"
          :options="securityClassifications"
        />
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <label for="edit_c_os">Operational Status</label>
        <b-form-select
          id="edit_c_os"
          v-model="operationalStatus"
          :options="operationalStati"
        />
      </b-col>
      <b-col>
        <label for="edit_c_trl">TRL</label>
        <b-form-select
          id="edit_c_trl"
          v-model="trl"
          :options="trls"
        />
      </b-col>
    </b-row>
    <hr>

    <b-row>
      <b-col>
        <b-button
          v-if="selected_entity2.context.labels.includes('Function')"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-info"
          class="float-left"
          @click="routeToFunction"
        >
          View/Edit Decomposition & Allocation
        </b-button>
        <b-button
          v-if="selected_entity2.context.labels.includes('System')"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-info"
          class="float-left"
          @click="routeToSystem"
        >
          View/Edit System Decomposition
        </b-button>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="float-right"
          @click="submitFunction"
        >
          Save Changes
        </b-button>

        <!--<b-button-->
        <!--  v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
        <!--  variant="flat-secondary"-->
        <!--  class="float-right"-->
        <!--&gt;-->
        <!--  Cancel-->
        <!--</b-button>-->
      </b-col>
    </b-row>
    <replace-names-modal :name_swap="name_swap" :new_name="name" @replace="doUpdateExtended" />
    <ComponentImageModal
      :component="id"
    />
  </b-form>
  <div v-else class="text-center mt-4">
    <b-spinner />
    <b-card>
      <span>Loading...</span>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import {
  BButton, BCol, BForm, BFormCheckbox, BFormInput, BFormSelect, BRow,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'
import ReplaceNamesModal from '@/components/Domain/Modals/ReplaceNamesModal.vue'
import ComponentImageModal from '@/views/Component/ComponentImageModal.vue'

export default {
  components: {
    TipTapEditor,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BButton,
    ReplaceNamesModal,
    ComponentImageModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: '',
      name: '',
      acronym: '',
      abstract: false,
      multiplicity: '',
      stereotype_selected: [],
      classification: '',
      operationalStatus: '',
      trl: '',
      description: '',
      validity: 'Valid',
      context_data: { attributes: {} },
      name_swap: {},
      showDescriptionEditor: false,
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
    ...mapGetters({
      stereotypes: 'constants/stereotypes',
      securityClassifications: 'constants/securityClassifications',
      operationalStati: 'constants/operationalStatus',
      trls: 'constants/trls',
    }),
    entityType() {
      return this.selected_entity2.context.labels ? this.selected_entity2.context.labels.filter(e => e !== 'Component') : []
    },
  },
  mounted() {
    this.fillFields(this.selected_entity2)
  },
  methods: {
    editDescription() {
      this.showDescriptionEditor = !this.showDescriptionEditor
    },
    fillFields(n) {
      const con = n.context
      this.name = con.details.name
      this.acronym = con.details.acronym
      this.description = con.details.description
      this.stereotype_selected = con.labels
      this.abstract = con.details.abstract === true
      this.multiplicity = con.details.multiplicity
      this.validity = con.details.validity
      this.id = con.details.id
      this.classification = con.details.classification ?? this.$store.state.model.defaultSecurityClassification
      this.operationalStatus = con.details.operational_status ?? this.$store.state.constants.defaultOperationalStatus.id
      this.trl = con.details.trl ?? this.$store.state.constants.defaultTrl.id
    },
    submitFunction(evt) {
      // pre submit user interaction if required
      const oldName = this.selected_entity2.context.details.name
      if (this.selected_entity2.context.details.name !== this.name
        && this.selected_entity2.context.labels
        && !this.selected_entity2.context.labels.includes('Function')) {
        console.log('Get the potentially impacted functions and requirements...')
        const params = {
          model: this.$store.state.model.id,
          new_name: this.name,
        }
        this.$http
          .get(`/api/v2/domain_model/get_impacted_fns/${this.selected_entity2.context.details.id}`, { params })
          .then(({ data }) => {
            console.log('Possible Impacts: ', data)
            this.name_swap = data
            if (
              this.name_swap.functions
              && this.name_swap.functions.length === 0
              && this.name_swap.requirements
              && this.name_swap.requirements.length === 0
            ) {
              this.doUpdate()
            } else {
              this.$bvModal.show('replace-names-modal')
            }
          })
      } else {
        this.doUpdate()
      }
    },
    doUpdate() {
      const payload = {
        data: {
          name: this.name,
          acronym: this.acronym,
          description: this.description,
          multiplicity: this.multiplicity,
          abstract: Boolean(this.abstract),
          labels: this.stereotype_selected,
          model: this.$store.state.model.id,
          classification: this.classification,
          operational_status: this.operationalStatus,
          trl: this.trl,
        },
        id: this.id,
      }

      this.$store.dispatch('domainModel/updateComponent', payload)
        .then(data => {
          this.$store.dispatch('domainModel/selectEntity2', data.id)
            .then(() => {
              console.log('After Update', this.selected_entity2)
              this.$emit('clicked')

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Updated',
                  icon: 'CheckIcon',
                  text: `Updated Component ${this.name}`,
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              })
            })
        })
    },
    doUpdateExtended(replacements) {
      this.$bvModal.hide('replace-names-modal')
      // update the component
      this.doUpdate()
      // update the extended names
      const payload = {
        model: this.$store.state.model.id,
        replacements,
      }
      this.$http
        .post('/api/v2/domain_model/rename_impacted_things', payload)
        .then(({ data }) => {
          this.$store.dispatch('domainModel/selectEntity2', this.selected_entity2.context.details.id)
        })
    },
    routeToFunction(node) {
      this.$router.push(
        {
          name: 'domain_ontology_focus',
          params: { focus: this.id },
        },
      )
    },
    routeToSystem(node) {
      this.$router.push(
        {
          name: 'domain_ontology_focus',
          params: { focus: this.id },
        },
      )
    },
    // Find related components that are children and not the parent
    findChildren() {
      const rels = this.selected_entity2.context.relationships
      const parent = this.selected_entity2.context.parent_rel?.parent

      const output = []

      // Sort through all rels, find related components
      for (let i = 0; i < rels.length; i++) {
        if (rels[i].rel_type === 'TYPE_OF' || rels[i].rel_type === 'PART_OF') {
          if (rels[i].target_props.id !== parent) {
            // Find related comps that aren't parents
            output.push({
              name: rels[i].target_props.qualified_name,
              id: rels[i].target_props.id,
              rel_type: rels[i].rel_type,
            })
          }
        }
      }
      return output
    },
    entityLabel(data) {
      // Depending on the label of the Entity, return a different icon
      switch (data) {
      case 'Resource':
        return 'BookOpenIcon'
      case 'Function':
        return 'ZapIcon'
      case 'Performer':
        return 'UserIcon'
      case 'Standard':
        return 'FileTextIcon'
      case 'Reference':
        return 'EyeIcon'
      case 'Agreement':
        return 'PenToolIcon'
      case 'Capability':
        return 'CheckSquareIcon'
      case 'Activity':
        return 'ActivityIcon'
      case 'Service':
        return 'ToolIcon'
      case 'System':
        return 'ServerIcon'
      case 'Objective':
        return 'TargetIcon'
      default:
        return ''
      }
    },
    openImageModal() {
      this.$bvModal.show('component-image-modal')
    },
    changeRel() {
      const payload = {
        model: this.$store.state.model.id,
        id: this.selected_entity2.context.details.id,
      }
      this.$http
        .post('/api/v2/domain_model/change_parent_rel', payload)
        .then(({ data }) => {
          this.$store.dispatch('domainModel/selectEntity2', this.selected_entity2.context.details.id)
        })
    },
  },
}
</script>
