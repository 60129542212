<template>
  <b-modal
    id="edit-alias-modal"
    title="Edit Alias"
    size="lg"
    @ok="submitFunction"
  >
    <!-- {{data}} -->
    <b-form-group label="Edit Alias:" label-for="input-1">
      <b-form-input id="input-1" v-model="name" required @keydown.enter.native="submitFunction" />
    </b-form-group>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="primary" :disabled="loading_status" @click="ok()">
        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Adding...
        </span>

        <span v-else> Add </span>
      </b-button>

      <b-button @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapState } from 'vuex'
import {
  BButton, BFormGroup, BFormInput, BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BButton,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    data: Object,
  },
  data() {
    return {
      name: '',
      loading_status: false,
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
  },
  watch: {
    data(newVal) {
      this.fillName(newVal.target_props.name)
    },
  },
  mounted() {
    if (this.data.target_props) this.fillName(this.data.target_props.name)
  },
  methods: {
    fillName(val) {
      this.name = val
    },
    submitFunction() {
      this.loading_status = true
      const selectedId = this.selected_entity2.context.details.id
      const payload = {
        name: this.name,
        model: this.$store.state.model.id,
      }
      this.$http
        // Delete old Alias then replace it with a new one, as there is no way to edit (or PUT) to an alias yet
        .delete(`/api/v2/domain_model/alias/${this.data.target_props.id}`)
        .then(() => {
          console.warn('Sending: ', payload)
          this.$http
            .post(`/api/v2/domain_model/add_alias/${selectedId}`, payload)
            .then(() => {
              this.loading_status = false
              this.$bvModal.hide('edit-alias-modal')
              this.$store.dispatch('domainModel/invalidateCache')
              this.$store.dispatch('domainModel/selectEntity2', selectedId)
              this.$store.dispatch('domainModel/getComponents')
            })
        })
    },
  },
}
</script>
