<template>
  <b-modal
    id="delete-relation-modal"
    title="Delete Relationship"
    size="lg"
    @ok="submitFunction"
  >
    <p class="my-2">
      Are you sure you want to delete the relationship: <b>{{ data ? data.source_str + " " + data.name + " " + data.target_str : "This Relation?" }}</b>?
    </p>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="danger" :disabled="loading_status" @click="ok()">
        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Deleting...
        </span>

        <span v-else> Delete </span>
      </b-button>

      <b-button @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapState } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    data: Object,
  },
  data() {
    return {
      loading_status: false,
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
  },
  mounted() {},
  methods: {
    submitFunction() {
      this.loading_status = true
      const selectedId = this.selected_entity2.context.details.id
      this.$http
        .post(`/api/v2/domain_model/delete_component_relation/${this.data.id}`)
        .then(() => {
          this.loading_status = false
          this.$bvModal.hide('delete-relation-modal')
          this.$store.dispatch('domainModel/selectEntity2', selectedId)
        })
    },
  },
}
</script>
