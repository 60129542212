<template>
  <b-modal
    id="edit-constraint-modal"
    :title="addOrEditText + ' Constraint'"
    size="lg"
    body-class="scroll-80vh"
    @ok="submitFunction"
    @show="getData"
  >
    <b-tabs>
      <b-tab title="General">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Name / Tag">
              <v-select
                :value="name"
                label="value"
                :options="filteredOptions"
                placeholder="Prefill from a predefined constraint or make your own"
                @search="onInputChange"
                @option:selected="populateOtherFields"
              >
                 <template #no-options>No predefined constraint '{{ name }}'</template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Description / Gist " label-for="constraint-gist">
              <b-form-textarea
                id="constraint-gist"
                v-model="gist"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Notes" label-for="constraint-note">
              <b-form-textarea
                id="constraint-note"
                v-model="note"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label for="edit_c_clas">Classification</label>
            <b-form-select
              id="edit_c_clas"
              v-model="classification"
              :options="securityClassifications"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Constraint Details">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Scale " label-for="constraint-scale">
              <b-form-textarea
                id="constraint-scale"
                v-model="scale"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Must (Essential) " label-for="constraint-must">
              <b-form-input
                id="constraint-must"
                v-model="must"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Plan (Very Important) " label-for="constraint-plan">
              <b-form-input
                id="constraint-plan"
                v-model="plan"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Stretch (Important) " label-for="constraint-stretch">
              <b-form-input
                id="constraint-stretch"
                v-model="stretch"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Wish (Desirable) " label-for="constraint-wish">
              <b-form-input
                id="constraint-wish"
                v-model="wish"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Qualifier ">
              <b-form-input v-model="qualifier" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Associations">
        <b-row v-if="selected_constraint" class="mb-2">
          <list-group-entity
            class="w-100 ml-1 mr-1"
            label="Qualification Records"
            :is-create="true"
            :entity-array="qualificationRecords"
            modal="create-qualification-record-modal"
          >
            <template #icon>
              <feather-icon icon="PlusIcon" />
            </template>
            <template #body="{ array }">
              <div class="mw-100 scroll-400">
                <record-list-group-item
                  v-for="record in array"
                  :key="record.id"
                  :records-item="record"
                  @update-qualification-record="openUpdateQrModal"
                  @delete-qualification-record="openDeleteQrModal"
                  @link-evidence-with-qualification-record="openLinkEvidenceModal"
                />
              </div>
            </template>
          </list-group-entity>

          <!-- Qualification Record Modals -->
          <create-qualification-record-modal :parent-id="selected_constraint.id" @on-create="onQrUpserted" />
          <div v-if="selectedQualificationRecordId">
            <update-qualification-record-modal :qr-id="selectedQualificationRecordId" @on-update="onQrUpserted" />
            <delete-qualification-record-modal :qr-id="selectedQualificationRecordId" @on-delete="onQrDeleted" />
            <link-evidence-with-qualification-record :qr-id="selectedQualificationRecordId" />
          </div>
        </b-row>

        <!-- Requirements -->
        <b-row v-if="requirements" class="mt-2">
          <b-col>
            <list-group-requirement
              label="Requirements"
              :entity-array="requirements"
              @beforeShowAssociator="loadRequirements"
              modal="associator-generic-requirements-edit"
            />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="outline-secondary" @click="cancel()">
        Discard
      </b-button>

      <b-button variant="success" :disabled="loading_status" @click="ok()">
        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Saving changes...
        </span>

        <span v-else>
          Save Changes
        </span>
      </b-button>
    </template>
    <!-- Requirements -->
    <associator-generic
      name="Requirements"
      suffix="-edit"
      :associated-items="selectedRequirementsMapped"
      :all-items="allRequirementsMapped"
      @associated="linkRequirements"
    />
  </b-modal>
</template>
<script>
import vSelect from 'vue-select/dist/vue-select'
import { nextTick } from '@vue/composition-api'
import { mapGetters, mapState } from 'vuex'
import {
  BButton,
  BCol, BFormGroup, BFormInput, BFormTextarea, BModal, BRow, BSpinner,
} from 'bootstrap-vue'
import ListGroupRequirement from '@/components/Forms/ListGroups/ListGroupRequirement.vue'
import AssociatorGeneric from '@/components/Generic/Associators/AssociatorGeneric.vue'
import RecordListGroupItem from '@/views/OQE/RecordsModals/RecordListGroupItem.vue'
import ListGroupEntity from '@/components/Forms/ListGroups/ListGroupEntity.vue'
import CreateQualificationRecordModal from '@/components/ObjectiveQualificationEvidence/Record/CreateQualificationRecordModal.vue'
import UpdateQualificationRecordModal from '@/components/ObjectiveQualificationEvidence/Record/UpdateQualificationRecordModal.vue'
import DeleteQualificationRecordModal from '@/components/ObjectiveQualificationEvidence/Record/DeleteQualificationRecordModal.vue'
import LinkEvidenceWithQualificationRecord from '@/components/ObjectiveQualificationEvidence/Record/LinkEvidence.vue'

export default {
  components: {
    vSelect,
    CreateQualificationRecordModal,
    UpdateQualificationRecordModal,
    DeleteQualificationRecordModal,
    LinkEvidenceWithQualificationRecord,
    ListGroupEntity,
    RecordListGroupItem,
    BRow,
    BCol,
    BFormGroup,
    BSpinner,
    BModal,
    BFormTextarea,
    BFormInput,
    BButton,
    ListGroupRequirement,
    AssociatorGeneric,
  },
  data() {
    return {
      filteredOptions: [],
      // DOM and Payload
      model: this.$store.state.model.id,
      name: '',
      gist: '',
      scale: '',
      meter: '',
      must: '',
      plan: '',
      stretch: '',
      wish: '',
      qualifier: '',
      note: '',
      classification: this.$store.state.model.defaultSecurityClassification,
      requirements: [],
      allRequirements: [],
      loading_status: false,
      qualificationRecords: [],
      selectedQualificationRecordId: '',
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2', 'selected_constraint']),
    ...mapGetters({
      qualityAttributes: 'constants/qualityAttributes',
      securityClassifications: 'constants/securityClassifications',
    }),
    addOrEditText() {
      return this.selected_constraint ? 'Edit' : 'Add'
    },
    allRequirementsMapped() {
      return this.allRequirements.map(item => ({
        value: {
          id: item.id,
          toSortBy: item.display_id,
        },
        text: `${item.display_id}. ${item.text.replace(/<\/?[^>]+(>|$)/g, '')}`,
      }))
    },
    selectedRequirementsMapped() {
      return this.requirements.map(item => ({
        value: {
          id: item.id,
          toSortBy: item.display_id,
        },
        text: `${item.display_id}. ${item.object_text.replace(/<\/?[^>]+(>|$)/g, '')}`,
      }))
    },
  },
  watch: {
    selected_constraint(newVal) {
      this.fillFields(newVal)
    },
  },
  mounted() {
    if (this.selected_constraint) {
      this.fillFields(this.selected_constraint)
    } else {
      this.resetFields()
    }
  },
  methods: {
    openUpdateQrModal(qrId) {
      this.selectedQualificationRecordId = qrId
      nextTick(() => {
        this.$bvModal.show(`update-qualification-record-${qrId}`)
      })
    },
    openDeleteQrModal(qrId) {
      this.selectedQualificationRecordId = qrId
      nextTick(() => {
        this.$bvModal.show(`delete-qualification-record-${qrId}`)
      })
    },
    openLinkEvidenceModal(qrId) {
      this.selectedQualificationRecordId = qrId
      nextTick(() => {
        this.$bvModal.show(`link-evidence-with-qualification-record-${qrId}`)
      })
    },
    getData() {
      if (this.selected_constraint) {
        this.fillFields(this.selected_constraint)
      } else {
        this.resetFields()
      }
      this.loading_status = false
    },
    loadRequirements() {
      this.loading_status = true
      const modelId = this.$store.state.model.id
      this.$http.get('/api/v2/requirements/get_requirements_simple', { params: { model: modelId } })
        .then(({ data }) => {
          this.allRequirements = data
        })
      this.loading_status = false
    },
    resetFields() {
      this.model = this.$store.state.model.id
      this.name = ''
      this.gist = ''
      this.scale = ''
      this.meter = ''
      this.must = ''
      this.plan = ''
      this.stretch = ''
      this.wish = ''
      this.qualifier = ''
      this.note = ''
      this.classification = this.$store.state.model.defaultSecurityClassification
      this.requirements = []
      this.qualificationRecords = []
      this.allRequirements = []
      this.loading_status = false
    },
    fillFields(val) {
      if (val === null) return
      this.filteredOptions = this.qualityAttributes
      this.name = val.name
      this.gist = val.gist
      this.scale = val.scale
      this.meter = val.meter
      this.must = val.must
      this.plan = val.plan
      this.stretch = val.stretch
      this.wish = val.wish
      this.qualifier = val.qualifier
      this.note = val.note
      this.classification = val.classification || this.$store.state.model.defaultSecurityClassification
      this.requirements = val.requirements
      this.qualificationRecords = val.qualification_records || []
    },
    submitFunction(e) {
      e.preventDefault()
      this.loading_status = true
      const selectedId = this.selected_entity2.context.details.id
      const payload = {
        model: this.$store.state.model.id,
        name: this.name,
        gist: this.gist,
        scale: this.scale,
        meter: this.meter,
        must: this.must,
        plan: this.plan,
        stretch: this.stretch,
        wish: this.wish,
        qualifier: this.qualifier,
        note: this.note,
        classification: this.classification,
        requirements: this.requirements,
        qualification_records: this.qualificationRecords,
      }
      let requestPath = `/api/v2/domain_model/add_constraint/${selectedId}`
      if (this.selected_constraint) requestPath = `/api/v2/domain_model/update_constraint/${this.selected_constraint.id}`
      this.$http.post(requestPath, payload).then(() => {
        this.loading_status = false
        this.$bvModal.hide('edit-constraint-modal')
        this.$store.dispatch('domainModel/selectEntity2', selectedId)
      })
        .catch(() => {
          this.loading_status = false
        })
    },
    populateOtherFields(item) {
      // Populates the `gist` and `scale` fields after you select a predefined name
      // The data should be with the name together
      // console.debug(item)
      this.name = item.name
      this.gist = item.gist
      this.scale = item.scale
    },
    onInputChange(text) {
      if (!text) return
      this.name = text
    },
    linkRequirements(reqs) {
      const temp = this.findItemById(reqs, this.allRequirements)

      this.requirements = temp.map(x => ({
        id: x.id,
        display_id: x.display_id,
        object_text: x.text,
        priority: x.priority,
      }))
    },
    findItemById(toAssociate, allItems) {
      // Associators generic spit out arrays of just IDs
      // all link methods need ID and Name
      // this method will find the full object by ID from the all items array
      function sortFunction(value) {
        return allItems.find(item => (item.id === value))
      }

      return toAssociate.map(sortFunction)
    },
    onQrDeleted() {
      if (this.qualificationRecords) {
        this.qualificationRecords = this.qualificationRecords.filter(obj => obj.id !== this.selectedQualificationRecordId)
      }
    },
    onQrUpserted(qrObj) {
      if (this.qualificationRecords) {
        const existing = this.qualificationRecords.find(obj => obj.id === qrObj.id)
        if (existing) {
          this.qualificationRecords = this.qualificationRecords.filter(obj => obj.id !== qrObj.id)
        }
        this.qualificationRecords.push(qrObj)
        this.qualificationRecords = this.qualificationRecords.toSorted(
          (a, b) => new Date(a.created).valueOf() - new Date(b.created).valueOf(),
        )
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/plugins/extensions/ext-component-context-menu.scss';
@import '~@core/scss/vue/libs/vue-autosuggest.scss';
  .scroll-400 {
    overflow-y: auto;
    max-height: 400px;
  }
  .scroll-80vh {
    overflow-y: auto;
    max-height: 80vh;
  }
</style>
