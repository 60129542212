<template>
  <b-modal
    id="create-alias-modal"
    title="Create Alias"
    size="lg"
    @ok="onSubmit"
    @cancel="clearForm"
  >
    <form ref="create-alias-modal-form" @submit.stop.prevent="onSubmit">
      <b-form-group
        label="Alias name"
        label-for="txtAliasName"
        invalid-feedback="An alias name is required"
        :state="aliasNameState"
      >
        <b-form-input
          id="txtAliasName"
          v-model="name"
          :state="aliasNameState"
          required
          @keydown.enter.native="onSubmit"
        />
      </b-form-group>
    </form>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="outline-secondary" @click="cancel()">
        Dismiss
      </b-button>

      <b-button variant="success" :disabled="loading" @click="ok()">
        <span v-if="loading">
          <b-spinner small type="grow" />
          Creating...
        </span>

        <span v-else>Create Alias</span>
      </b-button>

    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import {
  BButton, BFormGroup, BFormInput, BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BButton,
  },
  data() {
    return {
      name: '',
      aliasNameState: null,
      loading: false,
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs['create-alias-modal-form'].checkValidity()
      this.aliasNameState = valid
      return valid
    },
    onSubmit(evt) {
      evt.preventDefault()
      if (!this.checkFormValidity()) {
        return
      }
      this.loading = true
      const selectedId = this.selected_entity2.context.details.id
      const payload = {
        name: this.name,
        model: this.$store.state.model.id,
      }
      this.$http
        .post(`/api/v2/domain_model/add_alias/${selectedId}`, payload)
        .then(result => {
          this.clearForm()
          this.$store.dispatch('domainModel/invalidateCache')
          this.$store.dispatch('domainModel/selectEntity2', selectedId)
          this.$store.dispatch('domainModel/getComponents')
          this.$bvModal.hide('create-alias-modal')
        })
    },
    clearForm() {
      this.loading = false
      this.name = ''
      this.aliasNameState = null
    },
  },
}
</script>
