<template>
  <b-modal
    id="edit-instance-modal"
    title="Edit Instance"
    size="lg"
    ok-title="Save Instance"
    ok-variant="success"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    @ok="onSubmit"
    @cancel="clearForm"
  >
    <b-form>
      <b-row class="mb-1">
        <b-col cols="8">
          <label for="add_instance_input_1">Instance name</label>
          <b-form-input id="add_instance_input_1" v-model="name" placeholder="Instance name..." />
        </b-col>
        <b-col cols="4">
          <label for="add_instance_input_2">Version</label>
          <b-form-input id="add_instance_input_2" v-model="version" placeholder="Version..." />
        </b-col>
      </b-row>

      <label for="add_instance_rich_1">Description</label>
      <tip-tap-editor
        id="add_instance_rich_1"
        v-model="description"
        placeholder="Describe this instance..."
      />
    </b-form>
  </b-modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    TipTapEditor,
  },
  props: {
    instance: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    id: '',
    name: '',
    version: '',
    description: '',
  }),
  computed: {
    ...mapState({
      selected_entity: state => state.domainModel.selected_entity2,
    }),
    ...mapGetters({
    }),
  },
  watch: {
    instance(newVal) {
      this.fillFields(newVal)
    },
  },
  mounted() {
    this.fillFields(this.instance)
  },
  methods: {
    fillFields(data) {
      this.id = data.id
      this.name = data.name
      this.version = data.version
      this.description = data.description
    },
    onSubmit(evt) {
      evt.preventDefault()
      const params = {
        model: this.$store.state.model.id,
        name: this.name,
        version: this.version,
        description: this.description,
      }
      this.$http.put(`/api/v2/domain_model/instance/${this.id}`, params)
        .then(({ data }) => {
          console.debug('Updated instance: ', data)
          // if (this.$route.name !== 'domain_ontology') this.graph_refresh()
          this.$emit('updated', data)
          this.$bvModal.hide('edit-instance-modal')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Instance Updated',
              text: 'Successfully Updated Instance',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
    },
    clearForm() {
      this.name = ''
      this.version = ''
      this.description = ''
    },
  },
}
</script>
