<template>
  <b-modal
    id="add-instance-modal"
    title="Create New Instance"
    size="lg"
    ok-title="Create Instance"
    ok-variant="success"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    @ok="onSubmit"
    @cancel="clearForm"
  >
    <b-form>
      <b-row class="mb-1">
        <b-col cols="8">
          <label for="add_instance_input_1">Instance name</label>
          <b-form-input id="add_instance_input_1" v-model="name" placeholder="Instance name..." />
        </b-col>
        <b-col cols="4">
          <label for="add_instance_input_2">Version</label>
          <b-form-input id="add_instance_input_2" v-model="version" placeholder="Version..." />
        </b-col>
      </b-row>

      <label for="add_instance_rich_1">Description</label>
      <tip-tap-editor
        id="add_instance_rich_1"
        v-model="description"
        placeholder="Describe this instance..."
      />
    </b-form>
  </b-modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'

export default {
  components: {
    TipTapEditor,
  },
  data: () => ({
    name: '',
    version: '',
    description: '',
  }),
  computed: {
    ...mapState({
      selected_entity: state => state.domainModel.selected_entity2,
    }),
    ...mapGetters({
    }),
  },
  mounted() {
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault()
      const params = {
        model: this.$store.state.model.id,
        instances: [{
          name: this.name,
          version: this.version,
          description: this.description,
        }],
      }
      this.$http.post(`/api/v2/domain_model/components/${this.selected_entity.context.details.id}/instances`, params)
        .then(({ data }) => {
          console.debug('Added instance: ', data)
          // if (this.$route.name !== 'domain_ontology') this.graph_refresh()
          this.$emit('added', data)
          this.$bvModal.hide('add-instance-modal')
          // this.clearForm()
          // DO NOT Clear the form for adding components -
          // it is best to leave as usually add many of the same stereotype at once (e.g. Functional/System Decomp)
        })
      /*    this.api('addComponent',sub_obj).then(()=>{
                this.$bvModal.hide('add-entity-modal')
            }) */
    },
    clearForm() {
      this.name = ''
      this.version = ''
      this.description = ''
    },
  },
}
</script>
