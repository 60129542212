<template>
  <b-modal
    id="delete-instance-modal"
    title="Delete Instance"
    size="lg"
    centered
  >
    Are you sure you want to delete {{ instance.name }}?

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="danger" @click="submitFunction()">
        Delete
      </b-button>
      <b-button variant="outline-dark" @click="cancel()">
        Cancel
      </b-button>

    </template>
  </b-modal>
</template>
<script>
import { mapState } from 'vuex'
import { BButton } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
  },
  props: {
    instance: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    submitFunction(e) {
      const params = {
        model: this.$store.state.model.id,
      }
      console.debug('Delete Instance: ', this.instance)
      this.$http
        .delete(`/api/v2/domain_model/instance/${this.instance.id}`)
        .then(() => {
          this.$emit('deleted')
          this.$bvModal.hide('delete-instance-modal')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Instance Deleted',
              text: 'Successfully Deleted Instance',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>
