<template>
  <b-modal
    id="component-image-modal"
    title="Edit Component Image"
    size="lg"
    ok-title="Close"
    ok-only
    ok-variant="'outline-secondary"
    no-close-on-backdrop
    @show="onShow"
    @hide="onClose"
  >
    <div>
      <div class="text-center">
        <b-col
          v-if="loading"
          class="d-flex justify-content-center ml-1"
        >
          <b-spinner
            small
            class="align-self-center mr-1"
          />
          Loading, Please wait...
        </b-col>
        <b-col v-else>
          <span v-if="componentSelectedImage === null && componentImageList.length === 0">
            <h2>Please upload an image</h2>
          </span>
          <span v-else>
            <span v-if="componentSelectedImage">
              <h2 v-if="componentSelectedImage==='default'">
                  Please select a Primary Image
              </h2>
              <a v-else v-b-tooltip.hover.top="'Open Image In New Tab'" :href="componentSelectedImage" target="_blank">
                <img
                  class="img-fluid"
                  :src="componentSelectedImage"
                  alt="Primary Image"
                >
              </a>
            </span>
            <span v-else>
              {{ componentSelectedImage }}
              <h2>Please select an image for the Component</h2>
            </span>
            <span v-if="componentImageList && componentImageList.length > 0">
              <h3 class="mt-2 mb-2" style="text-align: center">
                Uploaded Images
              </h3>
              <p>{{ (slide + 1) }} of {{ componentImageList.length }}</p>
              <b-carousel
                id="carousel-1"
                ref="myCarousel"
                v-model="slide"
                class="text-center"
                :interval="0"
                no-animation
                controls
                indicators
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
              >
                <!-- Text slides with image -->
                <b-carousel-slide
                  v-for="image in componentImageList"
                  ref="cSlide"
                  :key="image.key"
                  :img-src="image.value"
                  :img-alt="image.key"
                  class="text-center"
                />
              </b-carousel>
            </span>
          </span></b-col>
        <div class="mt-3">
          <b-button @click="showFileUpload">
            Upload New Image
          </b-button>
          <b-button v-if="componentImageList.length > 0" @click="openImgInNewTab">
            View Full Screen
          </b-button>
          <b-button v-if="componentImageList.length > 0" variant="primary" @click="newPrimaryImage()">
            Use as Component Image
          </b-button>
          <b-button v-if="componentImageList.length > 0" variant="danger" @click="deleteImage()">
            Delete Image
          </b-button>
        </div>
      </div>

    </div>
    <b-form-file
      id="fileInput"
      v-model="componentImageFileResult"
      :state="Boolean(componentImageFileResult)"
      style="display: none"
      accept="image/*"
      @change="imageFileChanged"
    />
  </b-modal>
</template>

<style scoped lang="scss">

</style>
<script>
import Vue from 'vue'
import { BCol } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { ref } from '@vue/composition-api'

const cSlide = ref(null)

export default {
  name: 'ComponentImageModal',
  props: {
    component: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      cSlide,
    }
  },
  data() {
    return {
      currentDisplayedImage: null,
      componentSelectedImage: null,
      componentImageList: [],
      componentImageFileResult: null,
      loading: false,
      slide: 0,
      sliding: null,
    }
  },
  methods: {
    async onShow() {
      this.slide = 0
      this.loading = true
      this.componentSelectedImage = null
      this.componentImageList = []
      this.componentImageFileResult = null
      this.currentDisplayedImage = null
      const config = { headers: { 'Model-Id': store.state.model.id } }
      try {
        const response = await this.$coreService.get(`/v1/component/${this.component}/get_image_ids`, config)
        // config.responseType = 'blob'
        const imageData = response.data
        if (imageData.current_image && imageData.current_image !== '') {
          const currentImageResponse = await this.$coreService.get(`/v1/component/${this.component}/get_image/${imageData.current_image}`, config)
          if (currentImageResponse.status !== 200) {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Component Image',
                text: `Error Getting Current Image ${imageData.current_image}`,
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
            this.slide = 0
            this.loading = false
          }
          if (currentImageResponse.data) {
            this.componentSelectedImage = URL.createObjectURL(currentImageResponse.data)
          } else {
            this.componentSelectedImage = 'default'
          }
        }
        if (imageData.saved_images && !imageData.saved_images.includes('')) {
          Promise.all(imageData.saved_images.map(async image => {
            const savedImageResponse = await this.$coreService.get(`/v1/component/${this.component}/get_image/${image}`, config)
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Component Image',
                text: `Error Getting Uploaded Images ${imageData.image}`,
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
            this.loading = false
            this.componentImageList.push({ key: image, value: URL.createObjectURL(savedImageResponse) })
          }))
        }
      } catch {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Component Image',
            text: 'Error Getting Component Images',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
      this.loading = false
    },
    onClose() {
      this.slide = 0
      this.componentSelectedImage = null
      this.componentImageList = []
      this.componentImageFileResult = null
      this.loading = false
    },
    showFileUpload() {
      document.getElementById('fileInput').click()
    },
    async imageFileChanged(e) {
      this.loading = true
      if (e.target.files.length > 0) {
        this.componentImageFileResult = URL.createObjectURL(e.target.files[0])
        const formData = new FormData()
        formData.append('file', e.target.files[0])
        const config = { headers: { 'Model-Id': store.state.model.id } }
        const response = await this.$coreService.post(`/v1/component/${this.component}/upload_image`, formData, config)
        await this.onShow()
        this.loading = false
      }
    },
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    },
    openImgInNewTab() {
      window.open(this.componentImageList[this.slide].value, '_blank')
    },
    async newPrimaryImage() {
      this.loading = true
      const config = { headers: { 'Model-Id': store.state.model.id } }
      const response = await this.$coreService.patch(`/v1/component/${this.component}/current_image`, { current_image: this.componentImageList[this.slide].key }, config)
      await this.onShow()
      this.loading = false
    },
    async deleteImage() {
      this.loading = true
      const config = { headers: { 'Model-Id': store.state.model.id } }
      const response = await this.$coreService.delete(`/v1/component/${this.component}/delete_image/${this.componentImageList[this.slide].key}`, config)
      await this.onShow()
      this.loading = false
    },
  },
}

</script>
<style>
</style>
