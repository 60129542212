<template>
  <b-modal
    id="replace-names-modal"
    title="Manage Name Replacement"
    size="xl"
    ok-title="Do Updates"
    ok-variant="success"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    @ok="onSubmit"
    @cancel="clearForm"
  >
    <b-form>
      <div>
        <p>
          The name change of <span class="text-primary">{{ selected_entity.context.details.name }}</span> to
          <span class="text-primary">{{ new_name }}</span> impacts the names of the following functions and the text of the following requirements.
          <br>
          <span class="text-danger">Please review carefully before submitting</span>
        </p>
      </div>
      <hr>
      <h4 class="text-primary">Functions:</h4>
      <div v-if="name_swap.functions && name_swap.functions.length === 0">
        No Functions impacted by the name changed
      </div>
      <div v-else>
        <b-row>
          <b-col cols="4">
            Replace:
          </b-col>
          <b-col cols="6">
            <b-row>
              With:
            </b-row>
          </b-col>
        </b-row>
        <b-row
          v-for="fn in name_swap.functions"
          :id="fn.id"
          :key="fn.id"
          class="mb-1"
        >
          <b-col cols="4">
            <h5 class="text-primary">{{ fn.name }}</h5>
          </b-col>
          <b-col cols="6">
            <b-row>
              <b-form-input v-model="fn.new_name" placeholder="Renamed Function"></b-form-input>
            </b-row>
          </b-col>
          <b-col cols="2">
            <b-button variant="outline-secondary" @click="fn.new_name = fn.name" size="sm">Change Back</b-button>
          </b-col>
        </b-row>
      </div>

      <hr>

      <h4 class="text-primary">Requirements:</h4>
      <div v-if="name_swap.requirements && name_swap.requirements.length === 0">
        No Requirements impacted by the name changed
      </div>
      <div v-else>
        <b-row>
          <b-col cols="4">
            Replace:
          </b-col>
          <b-col cols="6">
              With:
          </b-col>
        </b-row>
        <b-row
          v-for="req in name_swap.requirements"
          :id="req.id"
          :key="req.id"
          class="mb-1"
        >
          <b-col cols="4">
            <span class="text-secondary">{{ req.display_id }}</span>
            {{ req.object_text }}
          </b-col>
          <b-col cols="6">
            <b-form-input v-model="req.new_object_text" placeholder="Renamed Requirement"></b-form-input>
          </b-col>
          <b-col cols="2">
            <b-button variant="outline-secondary" @click="req.new_object_text = req.object_text" size="sm">Change Back</b-button>
          </b-col>
        </b-row>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
  },
  props: {
    name_swap: {
      type: Object,
      required: true,
    },
    new_name: {
      type: String,
      required: true,
    },
  },
  data: () => ({
  }),
  computed: {
    ...mapState({
      selected_entity: state => state.domainModel.selected_entity2,
    }),
    ...mapGetters({
    }),
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault()
      console.log('Replacement name_swap: ', this.name_swap)
      this.$emit('replace', this.name_swap)
    },
    clearForm() {
    },
  },
}
</script>
